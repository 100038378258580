<template>
  <CModal :show.sync="modalShow" size="lg" centered :close-on-backdrop="false">
    <template #header>
      <h2>
        Run Test Deck{{
          !!selectedTestDeck ? `: ${selectedTestDeck.name}` : ''
        }}
      </h2>
    </template>
    <CForm :class="{ 'is-loading': isLoading }" @submit.prevent="() => false">
      <CRow>
        <CCol>
          <MSelect
            id="run-test-deck_connectivityId"
            label="Tax Engine Connection"
            placeholder="Connection Name"
            :required="connectionsOptions.length > 1"
            :readonly="connectionsOptions.length < 2"
            :disabled="connectionsOptions.length < 2"
            :loading="connectionsLoading"
            :options="connectionsOptions"
            :value.sync="connectivityId"
          />
        </CCol>
        <CCol class="pt-2 align-self-center">
          {{
            preview.url.length > 100
              ? `${preview.url.slice(0, 50)}...`
              : preview.url
          }}
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <MSelect
            id="run-test-deck_testDeckId"
            label="Test Deck"
            placeholder="Test Deck Name"
            readonly
            disabled
            :loading="testDecksLoading"
            :options="testDecksOptions"
            :value="testDeckId"
          />
        </CCol>
        <CCol class="pt-4 align-self-center">
          <p v-if="selectedTestDeck" id="run-test-deck_groupCount">
            {{ $format.number(preview.numberOfTests) }} tests in group
          </p>
          <p
            v-if="
              selectedTestDeck &&
              !!preview.testDescription &&
              preview.testDescription.length > 0
            "
            id="run-test-deck_groupDescription"
          >
            {{ preview.testDescription }}
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6">
          <MDatePicker
            id="run-test-deck_transactionDate"
            :value.sync="transactionDate"
          />
        </CCol>
      </CRow>
    </CForm>
    <template #footer>
      <CButton
        id="btn-run-test-deck_cancel"
        color="secondary"
        @click="handleCancel"
        >Cancel</CButton
      >
      <CButton
        id="btn-run-test-deck_submit"
        color="primary"
        :disabled="!canSubmit"
        @click="handleRun"
        >Run</CButton
      >
    </template>
  </CModal>
</template>
<script>
import api from '@/api';
import { MDatePicker } from '@/components';
import { formsMixin } from '@/mixins/forms-mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'RunTestDeckModal',
  components: {
    MDatePicker,
  },
  mixins: [formsMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    testDeckId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showState: this.show,
      isLoading: false,
      selectedTestDeck: null,
      preview: {
        url: '',
        numberOfTests: '',
        testDescription: null,
      },
      connectivityId: '',
      transactionDate: new Date(),
      selectedTaxEngineType: '',
    };
  },
  computed: {
    ...mapGetters('testing', [
      'connections',
      'testDecks',
      'connectionsLoading',
      'testDecksLoading',
    ]),
    extendedSubmitEvaluation() {
      return (
        !!this.connectivityId &&
        !!this.selectedTestDeck &&
        !!this.transactionDate
      );
    },
    modalShow: {
      get() {
        return this.showState;
      },
      set(v) {
        this.showState = v;
        this.$emit('update:show', v === true);
      },
    },
    testDecksOptions() {
      if (!this.selectedTestDeck) {
        return [];
      } else {
        return [
          Object.freeze({
            value: this.selectedTestDeck.id,
            label: this.selectedTestDeck.name,
          }),
        ];
      }
    },
    connectionsOptions() {
      return this.connections
        .filter((o) => {
          return (
            (o.engineType || o.taxEngineType)?.toUpperCase() ===
            this.selectedTaxEngineType
          );
        })
        .map((o) => {
          return Object.freeze({
            value: o.id,
            label: o.name,
          });
        });
    },
  },
  watch: {
    testDeckId(value) {
      let testDeck = this.testDecks.find((t) => t.id === value);
      this.setTestDeckData(testDeck);
    },
    connectivityId(value) {
      let connection = this.connections.find((c) => c.id === value);
      if (connection) {
        let url = connection.url;
        if (connection.description && connection.description.length > 0) {
          this.preview.url = connection.description;
        } else {
          this.preview.url = url.length > 100 ? `${url.slice(0, 50)}...` : url;
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    setTestDeckData(testDeck) {
      if (!testDeck) {
        this.preview = {
          ...this.preview,
          numberOfTests: 0,
          testDescription: '',
        };

        this.selectedTaxEngineType = '';
        this.selectedTestDeck = null;
        return;
      }

      this.selectedTestDeck = Object.freeze({ ...testDeck });
      this.preview = {
        ...this.preview,
        numberOfTests: testDeck.itemCount || 0,
        testDescription: testDeck.description?.trim() || '',
      };

      const selectedTaxEngineType = testDeck.fileType
        .substring(0, 3)
        .toUpperCase();

      this.selectedTaxEngineType = selectedTaxEngineType;

      const filteredConnections = this.connections.filter((c) => {
        return (c.engineType || c.taxEngineType) === selectedTaxEngineType;
      });

      if (filteredConnections.length === 1) {
        const c = filteredConnections[0];
        this.connectivityId = c.id;
      }
    },
    handleCancel() {
      this.modalShow = false;
    },
    handleRun() {
      const request = {
        groupId: this.selectedTestDeck.id,
        connectivityId: this.connectivityId,
        transactionDate: this.transactionDate,
      };

      let connection = this.connections.find(
        (c) => c.id === this.connectivityId,
      );
      api.testing
        .execute(request, connection.engineType)
        .then(() => {
          this.showSuccess('Test Run successfully scheduled').then(() => {
            this.modalShow = false;
            this.$emit('refresh');
          });
        })
        .catch((err) => {
          this.$log.error('error', err);
          this.$swal({
            title: 'Error',
            icon: 'error',
            text: 'The test run could not be started, if the problem persists please contact support.',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        });
    },
    fetchData() {
      this.isLoading = true;
      const self = this;
      Promise.all([
        this.$store.dispatch('testing/fetchConnections'),
        this.$store.dispatch('testing/fetchTestDecks'),
      ])
        .then(() => {
          if (this.testDeckId) {
            let testDeck = this.testDecks.find((o) => o.id === this.testDeckId);
            self.$nextTick(() => {
              self.setTestDeckData(testDeck);
            });
          }
        })
        .finally(() => {
          self.$nextTick(() => {
            self.isLoading = false;
          });
        });
    },
  },
};
</script>
