<template>
  <router-link
    v-if="showLink"
    :to="{
      name: routeName,
      params: { clientCode: clientCode },
    }"
  >
    <slot>
      {{ displayText }}
    </slot>
  </router-link>
  <span v-else>
    <slot>
      {{ displayText }}
    </slot>
  </span>
</template>
<script>
function trimToNull(value) {
  if (!value || value === '') {
    return null;
  } else {
    return value;
  }
}

export default {
  name: 'MClientClink',
  props: {
    value: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    routeName: {
      type: String,
      required: false,
      default: 'ViewClient',
    },
    displayClientCode: {
      type: Boolean,
      required: false,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      clientCode: this.getClientCode(this.value),
      displayText: this.displayClientCode
        ? this.getClientCode(this.value)
        : this.getClientName(this.value),
    };
  },
  computed: {
    showLink() {
      return !!this.clientCode && this.enabled !== false;
    },
  },
  watch: {
    value(value) {
      this.clientCode = this.getClientCode(value);
      this.displayText = this.displayClientCode
        ? this.getClientCode(this.value)
        : this.getClientName(this.value);
    },
  },
  methods: {
    getClientCode(value) {
      if (this.$_.isNil(value)) {
        return null;
      } else if (this.$_.isObjectLike(value)) {
        return trimToNull(this.$_.get(value, 'clientCode'));
      } else {
        return trimToNull(`${value}`);
      }
    },
    getClientName(value) {
      if (this.$_.isNil(value)) {
        return null;
      } else if (this.$_.isObjectLike(value)) {
        return trimToNull(this.$_.get(value, 'clientName'));
      } else {
        return trimToNull(`${value}`);
      }
    },
  },
};
</script>
